import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";
import { resolve } from "path";

export default {
  namespaced: true,
  state: {
    token: "",
    userData: {},
    totalCoin: 0,
    isICountUp: true,
    robotBox: {
      robot: false,
      coin: 0,
    },
    turbo_temp_times: 0,
    extraInvite: [],
    loginReward: {},
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },
    setLoginReward: (state, payload) => {
      state.loginReward = payload;
    },
    setTurboTempTimes: (state, payload) => {
      state.turbo_temp_times = payload;
    },
    setRobotBox: (state, payload) => {
      for (const key in state.robotBox) {
        Vue.prototype.$set(state.robotBox, key, payload[key]);
      }
    },
    setIsICountUp: (state, payload) => {
      state.isICountUp = payload;
    },
    setUserData: (state, payload) => {
      for (const key in payload) {
        Vue.prototype.$set(state.userData, key, payload[key]);
      }
    },
    setTotalCoin: (state, payload) => (state.totalCoin = payload),
    setExtraInvite: (state, payload) => (state.extraInvite = payload),
  },
  actions: {
    login: async ({ state, commit, dispatch }, [data, start_param, callback]) => {
      let inviteCode = "";
      let groupId = "";
      if (start_param && start_param.indexOf("ref_") !== -1) {
        let tstart_param = start_param.split("-");
        console.log("tstart_param", tstart_param);
        tstart_param.map((item) => {
          if (item.indexOf("ref_") !== -1) {
            inviteCode = item.replace("ref_", "");
          }
          if (item.indexOf("gid_") !== -1) {
            groupId = item.replace("gid_", "");
          }
        });
      }
      const rs = await Vue.prototype.$http.post("/auth/login", { initData: data, inviteCode, groupId });
      vailcode(rs, async () => {
        console.log(rs.data);
        //localStorage.setItem("token", rs.data);
        //cookie.set("token", rs.data, { expires: 15 });
        commit("setToken", rs.data.token);
        commit("setLoginReward", { reward: rs.data.reward, reason: rs.data.reason });
        commit("setUserData", rs.data.collect.userInfo);
        commit("wallet/setTaskCount", rs.data.task_count || 0, { root: true });
        commit("mine/setHourEarnings", rs.data.hour_earnings || 0, { root: true });
        commit("setRobotBox", {
          robot: rs.data.collect.robot,
          coin: rs.data.collect.coin,
        });
        typeof callback == "function" && callback(rs.data);
      });
    },
    getUserData: async ({ state, commit, dispatch }, [data, start_param]) => {
      if (!state.token && data) {
        await dispatch("login", [data, start_param, null]);
      }
      const rs = await Vue.prototype.$http.post("/coin/collect", { coin: 0, isLogin: 1 });
      vailcode(
        rs,
        () => {
          commit("setUserData", rs.data.userInfo);
          commit("setRobotBox", {
            robot: rs.data.robot,
            coin: rs.data.coin,
          });
        },
        async () => {
          if (data) {
            await dispatch("login", [data, start_param, null]);
            await dispatch("getUserData", [null, null]);
          }
        }
      );
    },
    powerPlus: ({ state, commit, dispatch }) => {
      setTimeout(() => {
        const userData = state.userData;
        let power = userData.power + userData.recharge_speed;
        if (power > userData.power_max) {
          power = userData.power_max;
        }
        let newInfo = state.userData;
        newInfo.power = power;
        commit("setUserData", newInfo);
        dispatch("powerPlus");
      }, 1000);
    },
    getExtraInvite: async ({ state, commit, dispatch }) => {
      const rs = await Vue.prototype.$http.post("/user/extra/invite", {});
      vailcode(rs, () => {
        commit("setExtraInvite", rs.data);
      });
    },
    //更新角色
    updateAvatar: async ({ state, commit, dispatch }, avatar) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post("/my/avatar/update", { avatar });
        vailcode(
          rs,
          async () => {
            await dispatch("login", [Vue.prototype.WebApp.initData, Vue.prototype.WebApp.initDataUnsafe.start_param || ""]);
            resolve(1);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //更新blow首页背景图
    updateIndexBackground: async ({ state, commit }, skin) => {
      return new Promise(async (resolve, reject) => {
        const rs = await Vue.prototype.$http.post("/my/skin/switch", { skin });
        vailcode(
          rs,
          async () => {
            resolve(1);
          },
          () => {
            resolve();
          }
        );
      });
      // const rs = await Vue.prototype.$http.post("/my/skin/switch", { skin: 2 });
      // vailcode(rs, () => {
      //   console.log(22222);
      // });
    },
    updateResultAvatar: ({ state, commit, dispatch }, { avatar, skin }) => {
      return new Promise(async (resolve, reject) => {
        await Promise.all([dispatch("updateAvatar", avatar),
         dispatch("updateIndexBackground", skin)
        ])
          .then(async (_) => {
            await dispatch("login", [Vue.prototype.WebApp.initData, Vue.prototype.WebApp.initDataUnsafe.start_param || ""]);
            resolve(1);
          })
          .catch((err) => {
            resolve(null);
          });
      });
    },
  },
  getters: {},
};
