import "core-js/modules/es.array.push.js";
import loadConfig from "@Web3WalletConfig/config";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "avatar",
  computed: {
    ...userVuex.mapState(["userData"]),
    loadConfig() {
      return loadConfig;
    }
  },
  data() {
    const tab = [{
      label: this.$lang("Avatar"),
      value: "avatar"
    }, {
      label: this.$lang("Background"),
      value: "background"
    }];
    return {
      tab,
      selectTab: "avatar",
      selectAvatar: null,
      isUseLoading: false,
      selectBackground: null
    };
  },
  watch: {
    "userData.avatar_url": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.selectAvatar = newVal;
      },
      deep: true,
      immediate: true
    },
    "userData.skin": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectBackground = newVal;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["updateResultAvatar"]),
    onTab(item) {
      this.selectTab = item.value;
    },
    onSelect(index) {
      this.selectAvatar = index;
    },
    onBgSelect(skin) {
      this.selectBackground = skin;
    },
    async onUse() {
      this.isUseLoading = true;
      const res = await this.updateResultAvatar({
        avatar: this.selectAvatar,
        skin: this.selectBackground
      });
      console.log("res", res);
      if (!res) {
        this.isUseLoading = false;
        return;
      }
      this.isUseLoading = false;
      this.$nextTick(() => {
        this.$router.push("/");
      });
    }
  },
  mounted() {}
};