var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "avatar"
  }, [_c('div', {
    staticClass: "avatar-main"
  }, [_c('div', {
    staticClass: "intro"
  }, [_c('div', {
    staticClass: "intro-main"
  }, [_vm.selectBackground ? _c('div', {
    staticClass: "intro-avatar",
    style: {
      background: 'url(' + require(`@images/blow/blow/bg/index-bg-${_vm.selectBackground}.jpg`) + ') #090e41 center/auto 160% no-repeat'
    }
  }, [_vm.selectAvatar ? _c('img', {
    attrs: {
      "src": require(`@images/blow/blow/${_vm.selectAvatar}/pb-0.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm.selectAvatar ? _c('img', {
    staticClass: "shadow",
    attrs: {
      "src": require(`@images/blow/blow/${_vm.selectAvatar}/pb-f-0.png`),
      "alt": ""
    }
  }) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "intro-info"
  }, [_c('div', {
    staticClass: "intro-info__name"
  }, [_vm._v(_vm._s(_vm.loadConfig.avatar[_vm.selectAvatar - 1].name))]), _c('div', {
    staticClass: "intro-info__text"
  }, [_vm._v(_vm._s(_vm.loadConfig.avatar[_vm.selectAvatar - 1].intro))]), !_vm.isUseLoading ? _c('div', {
    staticClass: "btn-use",
    on: {
      "click": _vm.onUse
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("USE")) + " ")]) : _c('div', {
    staticClass: "btn-use"
  }, [_c('div', {
    staticClass: "circleLoading"
  })])])])]), _c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: item.value === _vm.selectTab && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 0), _vm.selectTab === 'avatar' ? _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.loadConfig.avatar, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: _vm.selectAvatar == index + 1 && 'active',
      on: {
        "click": function ($event) {
          return _vm.onSelect(index + 1);
        }
      }
    }, [_c('div', {
      staticClass: "item-status"
    }), _c('div', {
      staticClass: "item-main"
    }, [_c('div', {
      staticClass: "item-avatar"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/blow/blow/${index + 1}/pb-0.png`),
        "alt": ""
      }
    }), _c('img', {
      staticClass: "shadow",
      attrs: {
        "src": require(`@images/blow/blow/${index + 1}/pb-f-0.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item__name"
    }, [_vm._v(_vm._s(item.name))])])]);
  }), 0) : _vm._e(), _vm.selectTab === 'background' ? _c('div', {
    staticClass: "list background"
  }, _vm._l(_vm.loadConfig.background, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: _vm.selectBackground == item.skin && 'active',
      on: {
        "click": function ($event) {
          return _vm.onBgSelect(item.skin);
        }
      }
    }, [_c('div', {
      staticClass: "item-status"
    }), _c('div', {
      staticClass: "item-main"
    }, [_c('div', {
      staticClass: "item-bg",
      style: {
        background: 'url(' + require('@images/blow/blow/bg/index-bg-basic.jpg') + ') center/auto 150% no-repeat'
      }
    })])]);
  }), 0) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };