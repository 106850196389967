class blow {
  constructor(canvasId, imageCount, type) {
    this.canvas = document.getElementById(canvasId);
    if (!this.canvas) return;
    this.ctx = this.canvas.getContext("2d");

    // 获取设备像素比
    const dpr = window.devicePixelRatio || 1;

    // 获取 canvas 的初始大小this.ctx
    const rect = this.canvas.getBoundingClientRect();
    rect.height = parseInt(rect.width);

    // 设置 canvas 的宽高为其 CSS 宽高乘以 DPR
    this.canvas.width = rect.width * 1.15 * dpr;
    this.canvas.height = rect.height * 1.15 * dpr;

    // 设置 canvas 的 CSS 宽高
    this.canvas.style.width = `${rect.width}px`;
    this.canvas.style.height = `${rect.height}px`;

    // 将缩放上下文
    this.ctx.scale(dpr, dpr);

    this.imageCount = imageCount;
    this.type = type;
    this.images = [];


    this.pb0 = new Image();
    this.pb0.src = require(`@images/blow/blow/${this.type}/pb-0.png`);

    this.pb1 = new Image();
    this.pb1.src = require(`@images/blow/blow/d/pb-1.png`);

    this.pb2 = new Image();
    this.pb2.src = require(`@images/blow/blow/d/pb-2.png`);

    this.aImage = new Image();
    this.aImage.src = require(`@images/blow/blow/${this.type}/pb-1-b.png`);
    this.bImage = new Image();
    this.bImage.src = require(`@images/blow/blow/${this.type}/pb-1-m.png`);
    this.loadImages();

    this.currentIndex = 0;
    this.isReversing = false;
    this.isAutoReversing = false;
    this.reverseInterval = null;
    this.clickTimeout = null;

    this.backgroundAbove1Y = this.canvas.height; // 动画起始位置
    this.startTime = null; // 记录动画开始时间
    this.duration = 900; // 动画持续时间2秒
    this.isAnimating = false;
    this.rectX = 0; // 矩形左上角X
    this.rectY = 0; // 矩形左上角Y
    this.rectWidth = this.canvas.width / 1.2; // 矩形宽度
    this.rectHeight = this.canvas.height / 1.1 / dpr; // 矩形高度

    this.drawImage();
    // 启动动画
    this.animateBackgroundAbove1();
    this.drawMask();

    // 绑定 canvas 点击事件
    // this.canvas.addEventListener("touchstart", () => this.onClick());
  }

  // 加载图片
  loadImages() {
    for (let i = 1; i <= this.imageCount; i++) {
      const img = new Image();
      img.src = require(`@images/blow/blow/${this.type}/pb-f-${i}.png`);
      this.images.push(img);
    }
  }

  // 绘制椭圆形蒙版
  drawMask() {
    this.ctx.globalCompositeOperation = "destination-in"; // 只保留蒙版区域
    this.ctx.beginPath();
    this.ctx.rect(this.rectX, this.rectY, this.rectWidth, this.rectHeight);
    this.ctx.fill();
    this.ctx.globalCompositeOperation = "source-over"; // 恢复默认绘图模式
  }

  drawImage() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const ratio = 3.55;
    // 获取每张图片的宽度和高度
    const pb0Width = this.pb0.naturalWidth / ratio;
    const pb0Height = this.pb0.naturalHeight / ratio;
    const pb1Width = this.pb1.naturalWidth / ratio;
    const pb1Height = this.pb1.naturalHeight / ratio;
    const pb2Width = this.pb2.naturalWidth / ratio;
    const pb2Height = this.pb2.naturalHeight / ratio;
    const currentImageWidth = this.images[this.currentIndex].naturalWidth / ratio;
    const currentImageHeight = this.images[this.currentIndex].naturalHeight / ratio;

    // 绘制图片
    if (this.pb2.complete) {
      this.ctx.drawImage(this.pb2, -26, 0, pb2Width, pb2Height);
    }

    if (this.pb0.complete) {
      this.ctx.drawImage(this.pb0, -26, 0, pb0Width, pb0Height);
    }

    if (this.isReversing) {
      if (this.bImage.complete) {
        this.ctx.drawImage(this.bImage, -26, 0, currentImageWidth, currentImageHeight);
      }
    } else {
      if (this.aImage.complete) {
        this.ctx.drawImage(this.aImage, -26, 0, currentImageWidth, currentImageHeight);
      }
    }

    if (this.images[this.currentIndex].complete) {
      this.ctx.drawImage(this.images[this.currentIndex], -26, -4, currentImageWidth, currentImageHeight);
    }

    if (this.pb1.complete) {
      this.ctx.drawImage(this.pb1, -26, 0, pb1Width, pb1Height);
    }
  }
  // 点击事件处理
  onClick() {
    // 如果正在倒退，停止倒退
    if (this.isReversing) {
      clearInterval(this.reverseInterval);
      this.isReversing = false;
      this.isAutoReversing = false;
    }

    // 更新图片
    this.updateImage();

    // 清除之前的倒退计时器
    clearTimeout(this.clickTimeout);

    // 2秒后开始倒退
    this.clickTimeout = setTimeout(() => {
      if (!this.isAutoReversing) {
        this.isReversing = true;
        this.isAutoReversing = true;
        this.reverseImages();
      }
    }, 100);
  }

  // 更新图片的逻辑
  updateImage() {
    if (!this.isReversing) {
      if (this.currentIndex < this.imageCount - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = this.imageCount - 1; // 停止递进
      }
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = 0; // 停止倒退
        clearInterval(this.reverseInterval);
        this.isReversing = false;
        this.isAutoReversing = false;
      }
    }
    this.drawImage();
  }

  // 倒退图片的逻辑
  reverseImages() {
    this.reverseInterval = setInterval(() => {
      if (this.isReversing) {
        this.updateImage();
      }
    }, 50); // 每50ms切换图片
  }

  // 位移动画逻辑
  animateBackgroundAbove1() {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.startTime = null; // 重新开始时间
      requestAnimationFrame((timestamp) => this.animate(timestamp));
    }
  }

  easeInOutCubic(t) {
    return t < 0.8 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  }

  // 动画函数，使用贝塞尔曲线控制速度
  animate(timestamp) {
    if (!this.startTime) this.startTime = timestamp; // 记录动画开始时间
    const elapsed = timestamp - this.startTime; // 已经过的时间
    const progress = Math.min(elapsed / this.duration, 1); // 动画进度 0-1

    // 使用贝塞尔曲线加速并设置目标位置
    const easeValue = this.easeInOutCubic(progress);
    // 位置从 80 -> -20 再回到 0
    if (progress < 0.8) {
      this.backgroundAbove1Y = 250 - 100 * easeValue; // 上移到 -20
    } else {
      const returnProgress = (progress - 1) * 2; // 0.5 - 1 区间的进度
      this.backgroundAbove1Y = 0 + 20 * this.easeInOutCubic(returnProgress); // 回落到 0
    }
    this.drawImage(); // 更新画布

    // 动画结束后停止，否则继续
    if (elapsed < this.duration) {
      requestAnimationFrame((timestamp) => this.animate(timestamp));
    } else {
      this.backgroundAbove1Y = 0; // 确保动画结束位置回到 0
      this.isAnimating = false;
    }
  }

  // 初始化显示第一张图片
  start() {
    this.images[0].onload = () => {
      this.drawImage();
    };
  }
}

export default blow;
